<template>
    <div class="wrap">
        <el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
        <el-alert title="操作提示" type="success" class="margin-bottom">
            <div>
                1. 标识“*”的选项为必填项，其余为选填项。<br />
                2. 请按提示栏信息进行商品添加。
            </div>
        </el-alert>
        <div class="box">
            <div class="form_box">
                <el-form ref="form" :model="form" label-width="120px" v-loading="loading">
                    <el-form-item label="员工姓名" :rules="rules.required">
                        <el-input v-model="form.name" maxlength="30"></el-input>
                    </el-form-item>
                    <el-form-item label="所在岗位"  :rules="rules.required">
                        <el-input v-model="form.post" maxlength="30"></el-input>
                    </el-form-item>
                    <el-form-item label="选择年月" :rules="rules.required">
                        <el-date-picker
                                v-model="changeTime"
                                type="month"
                                format="yyyy 年 MM 月"
                                value-format="yyyy-MM"
                                placeholder="选择年月"
                                @change="getTime"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="底薪" :rules="rules.required">
                        <el-input-number v-model="form.base_salary" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="岗位工资" :rules="rules.required">
                        <el-input-number v-model="form.post_wages" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="绩效工资" :rules="rules.required">
                        <el-input-number v-model="form.achievements_wages" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="做卡金额" :rules="rules.required">
                        <el-input-number v-model="form.card_money" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="做卡提成" :rules="rules.required">
                        <el-input-number v-model="form.card_commission" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="产品金额" :rules="rules.required">
                        <el-input-number v-model="form.product_money" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="产品提成" :rules="rules.required">
                        <el-input-number v-model="form.product_commission" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="现金额" :rules="rules.required">
                        <el-input-number v-model="form.cash_money" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="现金提成" :rules="rules.required">
                        <el-input-number v-model="form.cash_commission" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="其他" :rules="rules.required">
                        <el-input-number v-model="form.other" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="总工资" :rules="rules.required">
                        <el-input-number v-model="form.total_wage" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="备注" :rules="rules.required">
                        <el-input type="textarea" v-model="form.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="填表人" :rules="rules.required">
                        <el-input v-model="form.manager"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()">保存</el-button>
                        <!-- <el-button type="primary" @click="onSubmit()">保存并继续添加</el-button> -->
                        <el-button @click="$router.back()">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
    import rules from '../../../common/common.rules.js'
    export default {
        data() {
            return {
                rules,
                classify: [],
                loading: false,
                changeTime:'',
                form: {
                    id: this.$route.params.id, // int 服务ID
                    name: '', // string 客户名
                    year: '', // string 年份
                    month: '', // string 月份
                    post: '', // string 岗位
                    card_money: 0, // string 做卡金额
                    product_money: 0, // string 产品金额
                    cash_money: 0, // string 现金额
                    base_salary: 0, // string 底薪
                    post_wages: 0, // string 岗位工资
                    achievements_wages: 0, // string 绩效工资
                    card_commission: 0, // string 做卡提成
                    product_commission: 0, // int 产品提成
                    cash_commission: 0, // string 现金提成
                    other: 0, // int 其他
                    total_wage: 0, // string 总工资
                    manager:'',//填表人
                    remark:'',//备注
                }
            };
        },
        computed: {
            title() {
                if (this.form.id) {
                    return '编辑工资一览表'
                } else {
                    return '新增工资一览表'
                }
            }
        },
        mounted() {
            if (this.form.id) {
                this.$api.table.wagesDetails({
                    id: this.form.id
                }).then(res => {
                    this.form = res.data
                    this.changeTime = res.data.year + '-' + res.data.month
                })
            }
        },
        methods: {
            getTime(){
                console.log(this.changeTime)
                this.form.year = this.changeTime.substring(0,4)
                this.form.month = this.changeTime.substring(this.changeTime.length - 2,this.changeTime.length)
                console.log(this.form.year)
                console.log(this.form.month)
            },
            onSubmit() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        const loading = this.$loading();
                        this.$api.table.saveWages(this.form).then(res => {
                            loading.close();
                            if (res.code == 200) {
                                // 保存成功
                                this.$message.success(`保存成功`);
                                this.$router.back();
                            } else {
                                this.$message.error(res.msg);
                            }
                        }).catch(err => {
                            loading.close();
                            this.$alert(err);
                        });
                    } else {
                        this.$message.warning(`请检查输入错误`);
                        return false;
                    }
                });
            }
        },
    };
</script>

<style scoped lang="scss">
    .form_box {

        .el-input,
        .el-textarea {
            width: 500px;
        }
    }

</style>
